import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  stateList =      
  '[{"id": 1, "code": "QLD", "name": "Queensland"},' +
  '{"id": 2, "code": "VIC", "name": "Victoria"},' + 
  '{"id": 3, "code": "WA", "name": "Western Australia"},' +
  '{"id": 4, "code": "SA", "name": "South Australia"},' + 
  '{"id": 3,"code": "NT", "name": "Northern Territory"}]';

  stateListData

  constructor(private storage: Storage) { 
    this.stateListData = JSON.parse(this.stateList)
  }

  getState(code) {

    this.stateListData.forEach(state => {
      if (state.code == code) {
        return state.name;
      }
    });
    return ''
  }

  getStates() {
    return this.stateListData
  }
}
