import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams, NavController, AlertController } from '@ionic/angular';
import { GoalServiceService } from '../services/goal-service.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'app-goal-input-modal',
  templateUrl: './goal-input-modal.page.html',
  styleUrls: ['./goal-input-modal.page.scss'],
})
export class GoalInputModalPage implements OnInit {

  goalTitle
  goalDesc
  goalId

  public myForm: FormGroup
  private subgoalCount: number = 0

  private subgoals = []
  goal

  domains = []

  goalDomain = ''

  constructor(private modalController: ModalController,
              private goalService: GoalServiceService,
              private formBuilder: FormBuilder,
              private navParams: NavParams,
              private keyboard: Keyboard,
              private cd: ChangeDetectorRef,
              private navCtrl: NavController,
              private alertController: AlertController) {

                this.myForm = formBuilder.group({ })
                
                keyboard.hideFormAccessoryBar(false)

                this.goal = this.navParams.get('goal')

                if (! isNullOrUndefined(this.goal)) {
                  this.goalTitle = this.goal.title
                  this.subgoals = this.goal.subgoals
                  this.goalDomain = this.goal.domain
                  this.goalId = this.goal.id

                  this.subgoals.forEach(subgoal => {
                    this.myForm.addControl('subgoal_' + subgoal.id + '_title', new FormControl('', Validators.required))
                    this.myForm.addControl('subgoal_' + subgoal.id + '_desc', new FormControl('', Validators.required))
                    this.subgoalCount = subgoal.id + 1
                  })
                }

                this.goalService.getGoals().then(domains => {  
                  this.domains = domains              
                })
               }

  ngOnInit() { 
    this.cd.detectChanges()
  }

  async closeModal() {
    await this.modalController.dismiss()
  }

  async saveGoal() {
    if (this.goalTitle === '' || this.goalDesc === '') {

    } else if (! isNullOrUndefined(this.goal)) {
      this.goal.title = this.goalTitle
      this.goal.subgoals = this.subgoals
      this.goal.domain = this.goalDomain
      this.goalService.updateUserGoal(this.goal)
      await this.modalController.dismiss()
    } else {

      if (this.goalDomain == '') {
        this.goalDomain = 'about-me'
      }
      this.goalService.addUserGoal(this.goalTitle, this.subgoals, '', this.goalDomain)
      await this.modalController.dismiss()
    }
  }

  async deleteGoal() {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to delete your goal?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: async () => {
            this.goalService.deleteUserGoal(this.goal.id)
            await this.modalController.dismiss()
            this.navCtrl.back()
          }
        }
      ]
    })
    await alert.present();
  }

  addControl() {
    const title = 'subgoal_' + this.subgoalCount + '_title'
    const desc = 'subgoal_' + this.subgoalCount + '_desc'
    const control = {id: this.subgoalCount, title: '', desc: '', isCompleted: '' }
    this.subgoals.push(control)

    this.myForm.addControl('subgoal_' + this.subgoalCount + '_title', new FormControl('', Validators.required))
    this.myForm.addControl('subgoal_' + this.subgoalCount + '_desc', new FormControl('', Validators.required))

    this.subgoalCount++
  }

  removeControl(control) {
    const title = 'subgoal_' + control.id + '_title'
    const desc = 'subgoal_' + control.id + '_desc'
    this.myForm.removeControl(title)
    this.myForm.removeControl(desc)
    const index = this.subgoals.findIndex(x => x.id === control.id)
    if (index > -1) {
      this.subgoals.splice(index, 1)
    }
  }

  SelectCategory(domain) {
    this.goalDomain = domain.shorttitle
  }
}
