import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { computeStackId } from '@ionic/angular/directives/navigation/stack-utils';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.page.html',
  styleUrls: ['./select-modal.page.scss'],
})
export class SelectModalPage implements OnInit {
  
  inputValue: string = ''
  options
  optionsExtra
  modalTitle

  showSelfDescribe = false

  constructor(public navParams: NavParams,
              private modalController: ModalController) { 
              
    this.options = this.navParams.get('optionUser')
    this.optionsExtra = this.navParams.get('optionExtra')
    this.modalTitle = this.navParams.get('title')

    this.options = JSON.parse(this.options)
    this.inputValue = this.optionsExtra

    if (this.options['4'] != null) {
      if (this.options['4'].value == 1 && this.options['4'].name == 'Prefer to self-describe') {
        this.showSelfDescribe = true
      }
    }
  }

  ngOnInit() {
  }

  onSelect(name) {
    this.options.forEach(option => {
      if (option.name == name) {
        if (option.value == 0) {
          option.value = 1
          if (option.name.includes('self-describe')) {
            this.showSelfDescribe = true
          }
          option = option 
        } else {
          option.value = 0
          if (option.name.includes('self-describe')) {
            this.showSelfDescribe = false
          }
        }
      }
    })
  }

  async closeMenu() {
    const selfDescribe = this.inputValue
    await this.modalController.dismiss(this.options, selfDescribe)
  }
}
