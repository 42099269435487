import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'keephtml'
})
export class KeephtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, ...args: any[]): any {
    var text = value
    
    if (! isNullOrUndefined(text)) {
      var newString
      var regex = /href="(http[\S]+)"/g
      newString = text.replace(regex, 'href="$1" target="_system"')

      var youtubeRegex = /(src="\/\/)/g
      newString = newString.replace(youtubeRegex, 'src="https://')

      var pathingRegex = /href=\"#\/([\S]+)/g
      newString = newString.replace(pathingRegex, 'href="/$1')

      var imgRegex = /http[\S]+img\/([^\/]+)\//g
      newString = newString.replace(imgRegex, 'assets/cmsimg/$1"')

      return this.sanitizer.bypassSecurityTrustHtml(newString)
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(value)
    }

    return value
   
  }
}
