import { Component, OnInit, ViewChild } from '@angular/core';
import { MyValuesService } from '../services/my-values.service';
import { Platform, NavParams, NavController, IonSlides } from '@ionic/angular';
import { Firebase } from '@ionic-native/firebase/ngx';
import { MyValuesQuestionPage } from '../my-values-question/my-values-question.page';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DataReaderService } from '../services/data-reader.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-my-values-start',
  templateUrl: './my-values-start.page.html',
  styleUrls: ['./my-values-start.page.scss'],
})
export class MyValuesStartPage implements OnInit {

  @ViewChild ('ValuesSlider', {static: true}) slider: IonSlides

  question_id
  data
  questions
  showNext = false
  options
  valueResults

  constructor(private valueService: MyValuesService, 
    private navCtrl: NavController,
    private FBA: Firebase,
    private socialSharing: SocialSharing,
    private jsonBuilder: DataReaderService,
    private storage: Storage) {
   
    this.data = valueService.getQuestions().then((values) => {
      this.questions = values['questions']
    })
   }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.FBA.setScreenName('My Values')
    this.slider.lockSwipes(true)
  }

  closeQuestions() {
    this.navCtrl.back()
  }

  nextQuestion() {
    this.slider.lockSwipes(false)
    this.slider.slideNext().then(() =>{
      this.slider.lockSwipes(true)
        this.showNext = false
    })
  }

  slideChanged() {
    this.slider.getActiveIndex().then( index => {
        if (index != 0) {
          this.valueService.addAnsweredQuestions(this.options, this.question_id)
          this.valueResults = this.valueService.tallyAnswers()
        }
    })

    this.slider.isEnd().then((isTrue) => {
      if (isTrue) {
        this.jsonBuilder.getUser().then(( user => {    
          user['my_values'] = this.valueResults
          this.storage.set('user', user)
        }))
      }
    })
  }

  goBack() {
    this.slider.lockSwipes(false)
    this.slider.slidePrev().then(() => {
      this.slider.lockSwipes(true)
      this.showNext = true
    })
  }

  isLastQuestion() {
    if (parseInt(this.question_id) + 1 == this.valueService.getLength()) {
      return true
    } else {
      return false
    }
  }

  selectOption(question, option) {
    this.options = this.questions[question.id-1]['options']
    const index = this.options.findIndex(x => x.title == option.title)
    const selectedOption = this.options[index]
    const highest  = this.getOrder()

    if (selectedOption['order'] >= highest || selectedOption['order'] == null) {
      if (selectedOption['order'] == this.options.length || selectedOption['order'] == highest) {
        delete selectedOption['order'] 
        delete selectedOption['ticked']
      } else {
        selectedOption['order'] = highest + 1
        selectedOption['ticked'] = true
        if (selectedOption['order'] == this.options.length) {
          this.showNext = true
        }
      }
    }
  }

  getOrder() {
    let max = 0
    for (let i=0 ; i<this.options.length ; i++) {
      if (max == null || parseInt(this.options[i]['order']) > max) {
        max = this.options[i]['order']
      }
    }
    return max
  }


  shareToEmail() {
    this.socialSharing.canShareViaEmail().then(() => {
      this.socialSharing.shareViaEmail('', '', null, null,).then((value) => {
      })
    })
  }

  shareToFb() {
    this.socialSharing.canShareViaEmail().then(() => {
      this.socialSharing.shareViaFacebook('', '', null).then((value) => {
      })
    })
  }

  closeValues() {
   this.closeQuestions()
  }
}
