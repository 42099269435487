import { Component, OnInit, ChangeDetectorRef, SecurityContext, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ModalController, NavController } from '@ionic/angular';
import { MenuModalPage } from '../menu-modal/menu-modal.page';
import { Storage } from '@ionic/storage';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File } from '@ionic-native/file/ngx';
import {DomSanitizer} from '@angular/platform-browser'
import { UserAvatarService } from '../services/user-avatar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  
  assetFolder = ''

  @Input() profileImage

  constructor(private location: Location,
              private modalController: ModalController,
              private navCtrl: NavController,
              private storage: Storage, 
              private camera: Camera,
              private file: File,
              private filePath: FilePath,
              private sanitizer: DomSanitizer,
              private userAvatarService: UserAvatarService
              ) {
               
               }

  ngOnInit() {
    this.checkState()

    this.profileImage = this.userAvatarService.globalUserAvatar
  }

  goBack() {
    this.navCtrl.back()
  }

  ionViewWillEnter() {
    this.checkState()
  }

  ionViewDidEnter(){
  }

  checkState() {
    this.storage.get('currentState').then(data => {
      if (data == 'NT') {
        this.assetFolder = 'nt/'
      } else {
        this.assetFolder = ''
      }
    })
  }

  async openMenu() {
    const modal: HTMLIonModalElement = 
    await this.modalController.create({
      component: MenuModalPage,    
    });

    modal.onDidDismiss().then((res => { }));

    await modal.present();
  }

  toProfile() {
    this.navCtrl.navigateForward('/my-profile/#')
  }

  toSelector() {
    this.navCtrl.navigateForward('/home')
  }

  toGoals() {
    this.navCtrl.navigateForward('/my-goals')
  }
}
