import { UserAvatarService } from './services/user-avatar.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './navbar/navbar.component';
import { PipesModule } from './pipes/pipes.module';
import { KeephtmlPipe } from './Depreated/keephtml.pipe';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { MenuModalPageModule } from './menu-modal/menu-modal.module';
import { SharedModule } from './shared.module';
import { StateModalPage } from './state-modal/state-modal.page';
import { StateModalPageModule } from './state-modal/state-modal.module';
import { SelectModalPage } from './select-modal/select-modal.page';
import { SelectModalPageModule } from './select-modal/select-modal.module';
import { BudgetPlannerCategoryModalPageModule } from './budget-planner-category-modal/budget-planner-category-modal.module';
import { PopupPageModule } from './popup/popup.module';
import { GenericModalPage } from './generic-modal/generic-modal.page';
import { GenericModalPageModule } from './generic-modal/generic-modal.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Firebase } from '@ionic-native/firebase/ngx';
import { SqlService } from './services/sql.service';
import { MyValuesStartPage } from './my-values-start/my-values-start.page';
import { MyValuesStartPageModule } from './my-values-start/my-values-start.module';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { DBMeter } from '@ionic-native/db-meter/ngx';
import { GoalInputModalPageModule } from './goal-input-modal/goal-input-modal.module';
import { SpeechinputmodalPageModule } from './speechinputmodal/speechinputmodal.module';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import { APP_INITIALIZER } from "@angular/core";

export function init_app(global: UserAvatarService){
  // Init required providers before load the app
  // NOTE: this factory needs to return a function (that then returns a promise)
  return () => global.load()  // + any other providers...
}

@NgModule({
  declarations: [AppComponent,],
  entryComponents: [],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    IonicStorageModule.forRoot(), 
    HttpClientModule,
    MenuModalPageModule,
    StateModalPageModule,
    SelectModalPageModule,
    BudgetPlannerCategoryModalPageModule,
    PopupPageModule,
    GenericModalPageModule,
    MyValuesStartPageModule,
    GoalInputModalPageModule,
    SpeechinputmodalPageModule,
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    KeephtmlPipe,
    SQLite,
    Network,
    InAppBrowser,
    SocialSharing,
    Firebase,
    SpeechRecognition,
    DBMeter,
    Keyboard,
    ImagePicker,
    Camera,
    File,
    FilePath,
    UserAvatarService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [UserAvatarService],
      multi: true
    },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
