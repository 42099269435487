import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { navbar } from './navbar';
import { NavbarComponent } from './navbar/navbar.component';
import { KeephtmlPipe } from './Depreated/keephtml.pipe';
import { SlugifyHtmlPipe } from './pipes/slugify.pipe';
import { MoneyConverterPipe } from './pipes/moneyConverter.pipe';


@NgModule({
  declarations: [
    NavbarComponent, 
    KeephtmlPipe,
    SlugifyHtmlPipe,
    MoneyConverterPipe,
  ],
  imports: [
    CommonModule, 
    IonicModule.forRoot(), 

  ],
  exports: [
    NavbarComponent, KeephtmlPipe, SlugifyHtmlPipe,MoneyConverterPipe,

  ],
  providers: [

  ]
})
export class SharedModule { }