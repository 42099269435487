import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-budget-planner-category-modal',
  templateUrl: './budget-planner-category-modal.page.html',
  styleUrls: ['./budget-planner-category-modal.page.scss'],
})
export class BudgetPlannerCategoryModalPage implements OnInit {

  amount = 0

  option: string = 'Annually'

  category

  onlyOptions: boolean = false

  constructor(private modalController: ModalController, private navParams: NavParams) { 
    this.category = this.navParams.get('subCategories')

    if (! isNullOrUndefined(this.category)) {
      this.amount = this.category['value']
      this.option = this.category['option']
    } else {
      this.onlyOptions = this.navParams.get('options')
      this.option = this.navParams.get('option')
    } 
  }

  ngOnInit() {
  }

  async closeMenu() {

    if (! isNullOrUndefined(this.category)) {
      this.category['value'] = this.amount
      this.category['option'] = this.option 
      await this.modalController.dismiss( this.category)
    } else {
      
      await this.modalController.dismiss( this.option )
    }  
  }

  changeOption(option) {
    this.option = option
  }

}
