import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataReaderService } from '../services/data-reader.service';

@Component({
  selector: 'app-menu-modal',
  templateUrl: './menu-modal.page.html',
  styleUrls: ['./menu-modal.page.scss'],
})
export class MenuModalPage implements OnInit {

  currentState = 'QLD'

  constructor(private modalController: ModalController, 
              private jsonBuilder: DataReaderService) { 
                this.currentState = this.jsonBuilder.getCurrentState()
              }

  ngOnInit() {
  }

  async closeMenu() {
    await this.modalController.dismiss()
  }
}
