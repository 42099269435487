import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class MyValuesService {

  constructor(private storage: Storage, private http: HttpClient) { 
    this.getValues()
  }

  values = []
  questions = []

  answeredQuestions = []

  public async getValues(): Promise<any> {

    const values = await this.storage.get('values')
    if (isNullOrUndefined(values)) {
      let valuesData = await this.getQuestions()
      valuesData = valuesData
      this.questions = valuesData['questions']
      this.values = valuesData['values']
      return valuesData
    } else {
      return values
    }
  }

  public async getQuestions() {
    const valuesData = await this.http.get('assets/data/bootstrap-local.json').toPromise()
    return valuesData
  }

  public async getQuestion(questionID) {
    const valuesData = await this.getQuestions()
    this.questions = valuesData['questions']
    this.questions = valuesData['values']

    let question = valuesData['questions'][parseInt(questionID)-1]
    return question
  }

  public getLength() {
    return this.questions.length
  }

  public addAnsweredQuestions(answeredQuestions, questionID) {
    answeredQuestions.forEach(question => {
      this.answeredQuestions.push(question)
    });

    this.tallyAnswers()
  }

  public tallyAnswers() {

    this.answeredQuestions.forEach(answer => {
      var valueID = answer.value_id-1
      var order = answer.order
      this.values[valueID].rank += order
    })

    var sortValues = [...this.values]
    sortValues.sort((a, b) => parseInt(a.rank) - parseInt(b.rank))
    var myValues = sortValues.slice(0, 3)
    return myValues
  }
}
