import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core'
import { ModalController, AnimationController, NavController, Platform } from '@ionic/angular'
import { DBMeter } from '@ionic-native/db-meter/ngx'
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx'
import { isNullOrUndefined } from 'util'

@Component({
  selector: 'app-speechinputmodal',
  templateUrl: './speechinputmodal.page.html',
  styleUrls: ['./speechinputmodal.page.scss'],
})
export class SpeechinputmodalPage implements OnInit {

  @ViewChild('holder', {static: true}) holder:ElementRef
  @ViewChild('ring1', {static: true}) ring1:ElementRef
  @ViewChild('ring2', {static: true}) ring2:ElementRef
  @ViewChild('ring3', {static: true}) ring3:ElementRef
  @ViewChild('ring4', {static: true}) ring4:ElementRef
  @ViewChild('ring5', {static: true}) ring5:ElementRef
  @ViewChild('mic', {static: true}) micIcon:ElementRef

  speechTitle = 'Listening...'
  speechMessage = 'Try to say something'
  dbSubscription
  gotInput = true
  speechLevel = 1

  micHeight = 100
  micWidth = 100

  ring1M = 100
  ring2M = 120
  ring3M = 140
  ring4M = 160
  ring5M = 180

  options = {
    showPartial: true,
    showPopup: false
  }

  myTimer

  colorAnimation
  ringAnimation

  constructor(private modalController: ModalController,
              private speechRecognition: SpeechRecognition,
              private dbMeter: DBMeter, 
              private cd: ChangeDetectorRef,
              private animationCtrl: AnimationController,
              private navCtrl: NavController,
              private platform: Platform) { }

  ngOnInit() {
    this.colorAnimations()
    this.ringAnimations()
  }

  colorAnimations() {
    const ani1 = this.animationCtrl.create()
    .addElement(this.ring1.nativeElement)
    .iterations(Infinity).keyframes([
      { offset: 0, background: 'rgba(131, 0, 120, 1)'},
      { offset: 0.5, background: 'rgba(20, 155, 68, 1)'},
      { offset: 1, background: 'rgba(131, 0, 120, 1)'}
    ])
    const ani2 = this.animationCtrl.create()
    .addElement(this.ring2.nativeElement)
    .iterations(Infinity).keyframes([
      { offset: 0, background: 'rgba(245, 12, 81, 1)'},
      { offset: 0.5, background: 'rgba(98, 184, 77, 1)'},
      { offset: 1, background: 'rgba(245, 12, 81, 1)'},
    ])
    const ani3 = this.animationCtrl.create()
    .addElement(this.ring3.nativeElement)
    .iterations(Infinity).keyframes([
      { offset: 0, background: 'rgba(241, 66, 47, 1)'},
      { offset: 0.5, background: 'rgba(114, 180, 144, 1)'},
      { offset: 1, background: 'rgba(241, 66, 47, 1)'},
    ])

    const ani4 = this.animationCtrl.create()
    .addElement(this.ring4.nativeElement)
    .iterations(Infinity).keyframes([
      { offset: 0, background: 'rgba(253, 165, 84, 1)'},
      { offset: 0.5, background: 'rgba(254, 218, 84, 1)'},
      { offset: 1, background: 'rgba(253, 165, 84, 1)'},
    ])

    this.colorAnimation = this.animationCtrl.create()
    .duration(4000)
    .iterations(Infinity)
    .addAnimation([ani1, ani2, ani3, ani4])

    this.colorAnimation.play()
  }

  ringAnimations() {

    //Animations go from smallest to largest
    const micIconAnimation = this.animationCtrl.create()
      .addElement(this.micIcon.nativeElement)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform:'scale(1)', opacity: '1'},
        { offset: 0.4, transform:'scale(1.1)', opacity: '0.5'},
        { offset: 1, transform:'scale(1)', opacity: '0'}
      ])

    const centerRing = this.animationCtrl.create()
      .addElement(this.ring1.nativeElement)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform:'scale(1)', opacity: '1'},
        { offset: 0.5, transform:'scale(1.1)', opacity: '1'},
        { offset: 1, transform:'scale(1)', opacity: '1'}
      ])

    const secondRing = this.animationCtrl.create()
      .addElement(this.ring2.nativeElement)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform:'scale(0.1)', opacity: '1'},
        { offset: 0.7, transform:'scale(1.2)', opacity: '0.5'},
        { offset: 1, transform:'scale(1.3)', opacity: '0'}
      ])

    const thirdRing = this.animationCtrl.create()
      .addElement(this.ring3.nativeElement)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform:'scale(0.1)', opacity: '1'},
        { offset: 0.6, transform:'scale(1.2)', opacity: '0.5'},
        { offset: 1, transform:'scale(1.3)', opacity: '0'}
      ])

    const fourthRing = this.animationCtrl.create()
      .addElement(this.ring4.nativeElement)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform:'scale(0.1)', opacity: '1'},
        { offset: 0.5, transform:'scale(1.2)', opacity: '0.5'},
        { offset: 1, transform:'scale(1.3)', opacity: '0'}
      ])

    const fithRing = this.animationCtrl.create()
      .addElement(this.holder.nativeElement)
      .iterations(Infinity)
      .keyframes([
        { offset: 0, transform:'scale(0.1)', opacity: '1'},
        { offset: 0.4, transform:'scale(1.2)', opacity: '0.5'},
        { offset: 1, transform:'scale(1.3)', opacity: '0'}
      ])

    this.ringAnimation = this.animationCtrl.create()
      .duration(2000)
      .iterations(Infinity)
      .addAnimation([centerRing, secondRing, thirdRing, fourthRing, fithRing, micIconAnimation])

    this.ringAnimation.play()
  }

  ionViewDidEnter() {
    this.myTimer = setInterval(() => {this.endSpeech()}, 4000)
    this.checkSpeech()
  }

  async dismissClick() {
    await this.modalController.dismiss()
  }

  checkSpeech() {
    // Check permission
    this.speechRecognition.hasPermission()
    .then((hasPermission: boolean) => {
       // Request permissions
      if (! hasPermission) {
        this.speechRecognition.requestPermission()
        .then(() => {
          this.startSpeech()
        })
      } else {
        this.startSpeech()
      }
    }) 
  }

  startSpeech() {
    // Start listening
    this.speechRecognition.startListening(this.options).subscribe(
      (matches: string[]) => {
        this.addTextToMessage(matches[0])
      },
      (onerror) => console.log('error:', onerror)
    )

    if (this.platform.is('ios')) {
      this.dbSubscription = this.dbMeter.start().subscribe(data => {
        this.speechLevel = data
        this.changeLevel()
        this.cd.detectChanges()
      })
    }
  }

  addTextToMessage(newText) {
    this.gotInput = true
    this.speechMessage = this.speechMessage.replace('<span style="animation: fadeIn 1s ease-in-out;">', '')
    this.speechMessage = this.speechMessage.replace('</span>', '')
    if (newText.includes(this.speechMessage)) {
      let diff = this.findDiff(this.speechMessage, newText)
      this.speechMessage += '<span style="animation: fadeIn 1s ease-in-out;">' + diff + '</span>'
    } else {
      this.speechMessage = '<span style="animation: fadeIn 1s ease-in-out;">' + newText + '</span>'
    }

    this.cd.detectChanges()
    clearInterval(this.myTimer)
    this.myTimer = setInterval(() => {this.endSpeech()}, 2000)
  }

  findDiff(str1, str2) { 
    let diff= ''
    str2.split('').forEach(function(val, i) {
      if (val != str1.charAt(i))
        diff += val    
    })
    return diff
  }

  endSpeech() {
    clearInterval(this.myTimer)
    this.speechRecognition.stopListening()
    if (this.platform.is('ios')) {
      this.dbMeter.stop()
    }
    this.colorAnimation.stop()
    this.ringAnimation.stop()
    this.ring1M = 200
    
    // check to see if any input was caught
    if (this.speechMessage === 'Try to say something' || isNullOrUndefined(this.speechMessage)) {
      this.gotInput = false
      this.speechTitle = 'Didn\'t quite catch that'
    } else {
      if (this.speechMessage.length < 3) {
        this.gotInput = false
      } else {
        const searchTerm = this.speechMessage.replace('<span style="animation: fadeIn 1s ease-in-out;">', '').replace('</span>', '')
        this.navCtrl.navigateForward('/search/' + searchTerm).then(() => {
          this.speechMessage = ''
          this.dismissClick()
        })
      }
    }
  }

  tryAgain() {
    clearInterval(this.myTimer)
    this.myTimer = setInterval(() => {this.endSpeech()}, 4000)
    this.gotInput = true
    this.colorAnimation.play()
    this.ringAnimation.play()
    this.ring1M = 100
    this.startSpeech()
  }

  changeLevel() {
    this.ring5M = Math.pow((this.speechLevel + 80)*this.getDifference(), 1)
    this.ring4M = Math.pow((this.speechLevel + 60)*this.getDifference(), 1)
    this.ring3M = Math.pow((this.speechLevel + 40)*this.getDifference(), 1)
    this.ring2M = Math.pow((this.speechLevel + 20)*this.getDifference(), 1)
  }

  getDifference(): number {
    let diff = this.speechLevel - 40
    if (diff < 0) {
      diff = 0
    }
    const modifier = 1 + diff/40
    return modifier
  }
}
