import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';
import { Component, OnInit, SecurityContext } from '@angular/core'
import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File } from '@ionic-native/file/ngx';
import {DomSanitizer} from '@angular/platform-browser'
import { ActionSheetController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UserAvatarService {

  defaultImage = 'assets/img/icon-nav-profile.png'

  public globalUserAvatar = 'assets/img/icon-nav-profile.png'

  private userAvatar

  constructor(
    private imagePicker: ImagePicker,
    private camera: Camera,
    private file: File,
    private filePath: FilePath,
    private sanitizer: DomSanitizer,
    private storage: Storage, 
    ) { }

  public async load() { 
    this.globalUserAvatar = this.defaultImage
   }

  deleteImage(): string {
    this.storage.set('avatar', false)
    return this.defaultImage
  }

  async getImages(sourceType): Promise<string> {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      allowEdit: true,
      sourceType: sourceType
    }

    await this.camera.getPicture(options).then((imageData) => {
      //here converting a normal image data to base64 image data.  
      var base64ImageData = 'data:image/jpeg;base64,' + imageData;  

      this.writeFile(imageData, 'sortli/', 'user_avatar.jpg');  
      this.userAvatar = base64ImageData
      this.globalUserAvatar = base64ImageData

      this.storage.set('avatar', true)

      }, (error) => {  
          console.log('Error Occured: ' + error);       
      });  
      console.log('resetting image')

      return this.globalUserAvatar
  }
  
  /**
   * write a file in storage 
   *  
   *Base64Data : the image in base64 formart
   *FolderName : the folder name after the base directory
   *FileName  : the file name to save
   */   
  public async writeFile(base64Data: any, folderName: string, fileName: any) {
    let contentType = this.getContentType(base64Data);
    let DataBlob = this.b64toBlob(base64Data, contentType);
    
    let filePath = this.file.dataDirectory + folderName;

    // Check if the file dir exists before saving
    this.file.checkDir(this.file.dataDirectory, folderName).then(_ => {
      this.file.writeFile(filePath, fileName, DataBlob, { replace: true }).then((success) => {
      }).catch((err) => {
      })
    }).catch(err => {
      // If file does not exists, create it and then save to ii
      this.file.createDir(this.file.dataDirectory, folderName, false).then(result => {
        this.file.writeFile(filePath, fileName, DataBlob, { replace: true }).then((success) => {
        }).catch((err) => {
        })
      })
    });
  }
  
  public getContentType(base64Data: any) {
    let block = base64Data.split(";");
    let contentType = block[0].split(":")[1];
    return contentType;
  }

  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    // remove string so its just base64 data
    b64Data = b64Data.replace(/data\:image\/(jpeg|jpg|png)\;base64\,/gi, '');
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {

      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async getSanitizedImage(path, imageName): Promise<string> {
    await this.storage.get('avatar').then(async (avatar) => {
      if (isNullOrUndefined(avatar) || avatar == false) {
        this.globalUserAvatar = this.defaultImage
      } else {

        await this.file.readAsDataURL(path, imageName).then((data) => {
  
          let sanitized = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(data));
          this.globalUserAvatar=sanitized;

        }).catch((err) => {
        });
      }
    })
    return this.globalUserAvatar
  }
}
