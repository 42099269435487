import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { DataReaderService } from './data-reader.service';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';
import { computeStackId } from '@ionic/angular/directives/navigation/stack-utils';
import { Domain } from 'domain';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  currentVersion = 2;

  domainJSON = []

  private readonly beginURL = 'assets/data/bootstrap-';
  private readonly endURL = '.json';

  constructor(private storage: Storage,
              private jsonBuilder: DataReaderService, private http: HttpClient) { 
                this.setDomains()
              }

  public async setDomains(): Promise<any[]> {
    await this.jsonBuilder.initState()
    let state = await this.jsonBuilder.getCurrentState()
    await this.storage.get(state).then(async (domainJSON) => {
      let newVersion = false
      await this.storage.get('version').then(val => {
        if (val != this.currentVersion) {
          newVersion = true
          this.storage.set('version', this.currentVersion)
        } 
      })
      
      if (isNullOrUndefined(domainJSON) || newVersion ) {
          const boostrapURL = this.beginURL + state + this.endURL;
          await this.http.get(boostrapURL).toPromise().then((data:any) => {
            this.storage.set(state, data)
            data.domains.forEach(domain => {
              const domainName = domain.id
              this.domainJSON[domainName] = domain
              this.domainJSON[domainName]['categories'] = []
              data.categories.forEach(category => {
                if (category.domain_id == domain.id) {      
                  category['page'] = []
                  data.pages.forEach(page => {         
                    if (page.category_id == category.id) {
                      category['page'].push(page)
                    }
                  });
                  this.domainJSON[domainName]['categories'].push(category)
                }    
              });
            });
          })
      } else {
        
        const boostrapURL = this.beginURL + state + this.endURL;
        await this.http.get(boostrapURL).toPromise().then((data:any) => {
          this.storage.set(state, data)
          domainJSON = data
          domainJSON.domains.forEach(domain => {
            const domainName = domain.id
            this.domainJSON[domainName] = domain
            this.domainJSON[domainName]['categories'] = []
    
            domainJSON.categories.forEach(category => {
              if (category.domain_id == domain.id) {
                category['page'] = []
                domainJSON.pages.forEach(page => {
                  if (page.category_id == category.id) {
                    category['page'].push(page)
                  }
                });
                this.domainJSON[domainName]['categories'].push(category)
              }  
            });
          });
        })
      }
    })
    return this.domainJSON
  }

  public async getDomains() {
    if (! isNullOrUndefined(this.domainJSON) || this.domainJSON != [] ) {
      return this.domainJSON
    } else {
      await this.setDomains().then((domains) => {
        return this.domainJSON
      })
    }
  }

  public async getCategories(domain_id) {
    return this.domainJSON[domain_id]
  }

  public async getDomainsTitles(): Promise<any[]> {
    var state = this.jsonBuilder.getCurrentState();
    var domains = await this.storage.get(state)
    return domains['domains']

  }
}
