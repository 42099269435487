import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { resolve } from 'url';
import { isNullOrUndefined } from 'util';
import { ThemeService } from './theme.service';



@Injectable({
  providedIn: 'root'
})
export class DataReaderService {

  public promiseArray = [];

  public currentState: string = 'QLD';

  private readonly URL = 'assets/data/' //../../assets/data/bootstrap-QLD.json';
  private readonly beginURL = 'assets/data/bootstrap-';
  private readonly endURL = '.json';

  constructor(private http: HttpClient,
              private storage: Storage) { 
                this.storage.get('currentState').then( state => {
                    if (state == null) {
                      this.currentState = 'QLD'
                      this.setState('QLD')
                    } else { 
                      this.currentState = state
                    }
                })
               }

  public list(state: string): any {
    this.currentState = state;
    var boostrapURL = this.beginURL + this.currentState + this.endURL;
    return this.http.get(boostrapURL)
  }

  public setState(state: string): any {
    this.currentState = state;
    this.storage.set('currentState', this.currentState)
  }

  public getCurrentState(): string {
    return this.currentState;
  }

  public async initState()  {
    await this.storage.get('currentState').then( state => {
      if (isNullOrUndefined(state)) {
        this.currentState = 'QLD'
      } else {
        this.currentState = state
      }
    })
  }

  public async getUser(): Promise<any> {

    var user = await this.storage.get('user')

    if (isNullOrUndefined(user)) {
      let userJSON = await this.http.get('assets/data/user.json').toPromise()
      userJSON = userJSON['users'][0]
      this.storage.set('user', userJSON )
      return userJSON
    } else {
      return user
    }
  }

  public async storeUser() {
    return new Promise(res => {
      this.getUser().then( reso => {
        this.storage.set('user', reso )
        res(reso)
      })
    })
  }
}
