import { Component, OnInit } from '@angular/core';
import { DataReaderService } from '../services/data-reader.service';
import { Storage } from '@ionic/storage';
import { ModalController, LoadingController, NavParams } from '@ionic/angular';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-state-modal',
  templateUrl: './state-modal.page.html',
  styleUrls: ['./state-modal.page.scss'],
})
export class StateModalPage implements OnInit {

  states
  stateCode = 'QLD'
  modalTitle =  'Select Your State'
  currentState = 'QLD'

  constructor(private jsonBuilder: DataReaderService,
    private storage: Storage,
    private modalController: ModalController,
    public loadingController: LoadingController,
    private stateService: StateService,
    private navParams: NavParams) {

      this.states = stateService.getStates()
      this.stateCode = this.navParams.get('state')
      this.stateCode = this.jsonBuilder.getCurrentState()   
  }

  ngOnInit() {
  }

  state(state) {
    this.jsonBuilder.list(state).then(domains => {
      this.storage.set(state, domains)
      this.jsonBuilder.setState(state)
      this.closeMenu(state)
    })
  }

  async closeMenu(state) {
    await this.modalController.dismiss(state)
    this.presentLoading()
  }


  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      duration: 1000
    })
    await loading.present()

    const { role, data } = await loading.onDidDismiss()
  }


  updateModalState(state) {
    this.stateCode = state.code
    
    this.jsonBuilder.list(state.code).subscribe(domains => {
      this.storage.set(state.code, domains)
      this.jsonBuilder.setState(state.code)
      this.closeMenu(state.code)
    })
  }
}
