import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform, NavController } from '@ionic/angular';
import { DomainService } from '../services/domain.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.page.html',
  styleUrls: ['./generic-modal.page.scss'],
})
export class GenericModalPage implements OnInit {

  cordova: any
  goal
  message
  title
  domain = {
    shorttitle: 'empty'
  }
  isGoal = false

  constructor(private modalController: ModalController, 
    private params: NavParams, 
    private domainService: DomainService, 
    private platform: Platform, 
    private iab: InAppBrowser,
    private navCtrl: NavController,
    private socialSharing: SocialSharing) {

      //Check to see if the modal is coming from a goal or not
      if (params.get('goal')) {
        this.isGoal = true

        this.goal = params.get('goal')
        this.message = this.goal.popup_message

        this.domainService.getDomainsTitles().then(domains => {
          domains.forEach(domain => {
            if (domain.id == this.goal.domain_id) {
              this.domain = domain
            }
          })
        })

        if (params.get('isGeneric')) {
          this.message = `
          <h1> Congratulations on completing your goal, <br>` + this.goal.title + `!</h1>
          <p> Feel free to share your achievement on Facebook or email </p>
          `
          this.domain.shorttitle = this.goal.domain
        }
      } else {
        this.message = params.get('message')
        this.title = params.get('title')
      }

      
   }

  ngOnInit() {
  }

  async closeModal() {  
    await this.modalController.dismiss()
  }


  async goHome() {
    this.navCtrl.navigateBack('/home')
    await this.modalController.dismiss()
  }

  rateApp() {
    var iosLink, winLink, androidLink, currentLink
      
    iosLink = 'https://itunes.apple.com/au/app/sortli/id927253683?ls=1&mt=8'
    winLink = 'http://www.windowsphone.com/s?appid=e69c1bc9-e023-43ac-8317-ca05894283fb'
    androidLink = 'https://play.google.com/store/apps/details?id=com.docs.sortli'
    currentLink = iosLink

    //override based on device - if on desktop just keep IOS
    if (this.platform.is('android')) currentLink = androidLink

    this.openLink(currentLink)
  }

  openLink(link) {
    this.iab.create(link, '_system', 'location=yes')
  }


  isLastGoal() {
    return true
  }

  shareToEmail() {
    this.socialSharing.canShareViaEmail().then(() => {
      this.socialSharing.shareViaEmail('', '', null, null,).then((value) => {
      })
    })
  }

  shareToFb() {
    this.socialSharing.canShareViaEmail().then(() => {
      this.socialSharing.shareViaFacebook('', '', null).then((value) => {
      })
    })
  }

}
