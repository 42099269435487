import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'domain/:domain_id',
    loadChildren: () => import('./domain-page/domain-page.module').then( m => m.DomainPagePageModule)
  },
  {
    path: 'domain/:domain_id/category/:category_id',
    loadChildren: () => import('./category-page/category-page.module').then( m => m.CategoryPagePageModule)
  },
  {
    path: 'number-domain',
    loadChildren: () => import('./number-domain/number-domain.module').then( m => m.NumberDomainPageModule)
  },
  {
    path: 'menu-modal',
    loadChildren: () => import('./menu-modal/menu-modal.module').then( m => m.MenuModalPageModule)
  },
  {
    path: 'my-profile/:action',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'my-goals',
    loadChildren: () => import('./my-goals/my-goals.module').then( m => m.MyGoalsPageModule)
  },
  {
    path: 'state-modal',
    loadChildren: () => import('./state-modal/state-modal.module').then( m => m.StateModalPageModule)
  },
  {
    path: 'select-modal',
    loadChildren: () => import('./select-modal/select-modal.module').then( m => m.SelectModalPageModule)
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('./bookmarks/bookmarks.module').then( m => m.BookmarksPageModule)
  },
  {
    path: 'search/:searchQuery',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'budget-planner',
    loadChildren: () => import('./budget-planner/budget-planner.module').then( m => m.BudgetPlannerPageModule)
  },
  {
    path: 'budget-planner/:category_id',
    loadChildren: () => import('./budget-planner-category/budget-planner-category.module').then( m => m.BudgetPlannerCategoryPageModule)
  },
  {
    path: 'budget-planner-category-modal',
    loadChildren: () => import('./budget-planner-category-modal/budget-planner-category-modal.module').then( m => m.BudgetPlannerCategoryModalPageModule)
  },
  {
    path: 'my-values-start',
    loadChildren: () => import('./my-values-start/my-values-start.module').then( m => m.MyValuesStartPageModule)
  },
  {
    path: 'my-values-question/:question_id',
    loadChildren: () => import('./my-values-question/my-values-question.module').then( m => m.MyValuesQuestionPageModule)
  },
  {
    path: 'numbers',
    loadChildren: () => import('./numbers/numbers.module').then( m => m.NumbersPageModule)
  },
  {
    path: 'talk-someone/:domain_id',
    loadChildren: () => import('./talk-someone/talk-someone.module').then( m => m.TalkSomeonePageModule)
  },
  {
    path: 'goal/:goal_id',
    loadChildren: () => import('./goal/goal.module').then( m => m.GoalPageModule)
  },
  {
    path: 'goal/:goal_id/subgoal/:subgoal_id',
    loadChildren: () => import('./subgoal/subgoal.module').then( m => m.SubgoalPageModule)
  },
  {
    path: 'my-values-results',
    loadChildren: () => import('./my-values-results/my-values-results.module').then( m => m.MyValuesResultsPageModule)
  },
  {
    path: 'nextstep',
    loadChildren: () => import('./nextstep/nextstep.module').then( m => m.NextstepPageModule)
  },
  {
    path: 'budget-planner-summary',
    loadChildren: () => import('./budget-planner-summary/budget-planner-summary.module').then( m => m.BudgetPlannerSummaryPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'help/:content',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'intro-welcome',
    loadChildren: () => import('./intro-welcome/intro-welcome.module').then( m => m.IntroWelcomePageModule)
  },
  {
    path: 'intro-user',
    loadChildren: () => import('./intro-user/intro-user.module').then( m => m.IntroUserPageModule)
  },
  {
    path: 'popup',
    loadChildren: () => import('./popup/popup.module').then( m => m.PopupPageModule)
  },
  {
    path: 'generic-modal',
    loadChildren: () => import('./generic-modal/generic-modal.module').then( m => m.GenericModalPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'goal-input-modal',
    loadChildren: () => import('./goal-input-modal/goal-input-modal.module').then( m => m.GoalInputModalPageModule)
  },
  {
    path: 'usergoal/:goal_id',
    loadChildren: () => import('./usergoal/usergoal.module').then( m => m.UsergoalPageModule)
  },
  {
    path: 'usergoal/:goal_id/subgoal/:subgoal_id',
    loadChildren: () => import('./usersubgoal/usersubgoal.module').then( m => m.UsersubgoalPageModule)
  },
  {
    path: 'speechinputmodal',
    loadChildren: () => import('./speechinputmodal/speechinputmodal.module').then( m => m.SpeechinputmodalPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
