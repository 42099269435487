import { TutorialPage } from './../tutorial/tutorial.page';
import { Component, OnInit, ElementRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.page.html',
  styleUrls: ['./popup.page.scss'],
})
export class PopupPage implements OnInit {

  notificationObject
  link = ''

  title = ''
  description = ''

  // popup type is default to popup
  // another type is confirmation
  type = 'popup'

  constructor(private params: NavParams, 
              private viewCtrl: ModalController,
              private elRef: ElementRef) {

    this.notificationObject = params.get('notification')

    if (! isNullOrUndefined(this.notificationObject)) {
      if (! this.notificationObject.includes('CTA') || isNullOrUndefined(this.notificationObject.CTA)) {
        this.link = this.notificationObject.category_id
        this.title = this.notificationObject.title
        this.description = this.notificationObject.description
      } else {
        this.link = this.notificationObject.CTA
      } 
    } else {
      this.title = params.get('title')
      this.description = params.get('message')
    }
  }

  ngOnInit() {
  }

  ionViewDidLoad() {}

  onClick(value) {
    this.viewCtrl.dismiss(value)
  }

  dismissClick(confirmation = false) {
    if (this.type == 'popup') {
      this.viewCtrl.dismiss(this.link)
    } else {
      this.viewCtrl.dismiss(confirmation)
    } 
  }
}
