import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { DataReaderService } from './data-reader.service';
import { isNullOrUndefined } from 'util';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GoalServiceService {

  goals = []

  constructor(private storage: Storage,
              private jsonBuilder: DataReaderService) { }
              
  public async getSubgoals(): Promise<any> {
    const state = this.jsonBuilder.getCurrentState();
    var goalJSON = []

    await this.storage.get(state).then((val) => {
      goalJSON = val.subgoals
    })

    return goalJSON
  }

  public async getGoals(): Promise<any[]> {

    const state = this.jsonBuilder.getCurrentState();
    var goalJSON = []

    await this.storage.get(state).then((val) => {
      val.domains.forEach(domain => {
        const domainName = domain.title
        domain['goals'] = []

        val.goals.forEach(goal => {
          if (goal.domain_id == domain.id) {
            goal['subgoals'] = []
            val.subgoals.forEach(subgoal => {
              if (subgoal.goal_id == goal.id) {
                goal['subgoals'].push(subgoal)
              }
            });
            domain['goals'].push(goal)
          }
        });
        goalJSON.push(domain)
      });
    })
    return goalJSON;   
  }

  /// SUBGOALS ///
  //ObjectID = subgoalID

  private createSubgoalJSON(objectID, state) {
    const goalObject = {
      objectID,
      state,
      completed: '1',
    }
    return goalObject;
  }

  addSubgoal(objectID, state) {
    const subGoal = this.createSubgoalJSON(objectID, state)
    
    this.getCompletedSubgoals().then(subgoals => {
      let subgoalData
      if (isNullOrUndefined(subgoals)) {
        subgoals = []      
      } 
      subgoalData = subgoals       
      subgoalData.push(subGoal)
      this.storage.set('subgoals_completed', subgoalData)
    })
  }

  removeSubgoal(objectID, state) {
    this.getCompletedSubgoals().then(subgoals => {
      for (let index = 0; index < subgoals.length; index++) {
        const subgoal = subgoals[index];
        if (subgoal.objectID == objectID && subgoal.state == state) { 
          subgoals.splice(index, 1)
        }        
      }
      this.storage.set('subgoals_completed', subgoals)
    })
  }

  public async getCompletedSubgoals() {
    const subgoals_completed = await this.storage.get('subgoals_completed')
    return subgoals_completed;
  }

  //// USER GOAL SECTION
  public async getUserGoals(): Promise<any[]> {
    let userGoalsJSON
    await this.storage.get('userGoals').then(userGoals => {
      userGoalsJSON = userGoals
    })
    return userGoalsJSON
  }

  private createUserGoalJSON(id, title, subgoals, isCompleted, domain) {
    const jsonObject = {
      id,
      title,
      subgoals,
      isCompleted ,
      domain,
    };
    return jsonObject;
  }

  public addUserGoal(title, subgoals, isCompleted, domain) {
    this.getUserGoals().then(userGoals => {
      let highestId
      if (isNullOrUndefined(userGoals)) {
        userGoals = []
        highestId = 0
      } else {
        highestId = userGoals.length <= 0 ? 0 : Math.max(...userGoals.map(goal => goal.id))
      }
      const userGoal = this.createUserGoalJSON(highestId + 1, title, subgoals, isCompleted, domain)

      let JSONuserGoals = userGoals    
      JSONuserGoals.push(userGoal)
      this.storage.set('userGoals', JSONuserGoals)
    })
  }

  public deleteUserGoal(id) {
    this.getUserGoals().then(userGoals => {
      userGoals.forEach( function (userGoal, i) {
        if (userGoal.id == id) {
          userGoals.splice(i, 1)
        }
      })
      this.storage.set('userGoals', userGoals)
    })
  }

  public updateUserGoal(goal) {
    this.getUserGoals().then(userGoals => {
      userGoals.forEach( function (userGoal, i) {
        if (userGoal.id == goal.id) {
          userGoal = goal
          userGoals[i] = goal
        }
      })
      this.storage.set('userGoals', userGoals)
    })
  }
}
